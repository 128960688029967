import * as _fastXmlParser2 from "fast-xml-parser";

var _fastXmlParser = "default" in _fastXmlParser2 ? _fastXmlParser2.default : _fastXmlParser2;

var exports = {};
const parser = _fastXmlParser;

const isSvg = input => {
  if (input === undefined || input === null) {
    return false;
  } // TODO: Remove the `.replace` call when using `fast-xml-parser@4` which has fixed the bug.


  input = input.toString().trim().replace(/\n/g, " ");

  if (input.length === 0) {
    return false;
  } // Has to be `!==` as it can also return an object with error info.


  if (parser.validate(input) !== true) {
    return false;
  }

  let jsonObject;

  try {
    jsonObject = parser.parse(input);
  } catch (_) {
    return false;
  }

  if (!jsonObject) {
    return false;
  }

  if (!("svg" in jsonObject)) {
    return false;
  }

  return true;
};

exports = isSvg; // TODO: Remove this for the next major release

exports.default = isSvg;
export default exports;